import request from '@/utils/request';

// 包裝原有的 Axios request 給 RTKQuery 使用
export const axiosBaseQuery = () => async (args) => {
  const { url, method, data, params } = args;
  try {
    const result = await request({
      url,
      method,
      data,
      params,
    });
    // 只傳入 data，不把 Axios 的 headers, config... 傳進來，會引起序列化問題
    return {
      data: {
        data: result.data,
      },
    };
  } catch (axiosError) {
    return {
      error: {
        status: axiosError.response?.status,
        data: axiosError.response?.data,
      },
    };
  }
};
