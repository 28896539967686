export enum userRoleEnum {
  Owner = 1,
  CoOwner = 2,
  Manager = 3,
  Internal = 4,
  External = 5,
  General = 6,
}

export interface ProjectRole {
  value: userRoleEnum;
  label: string;
  backgroundColor: string;
  borderColor: string;
}

export const userProjectRoleConst: Record<userRoleEnum, ProjectRole> = {
  [userRoleEnum.Owner]: {
    value: userRoleEnum.Owner,
    label: 'Owner',
    backgroundColor: '#FFF2D9',
    borderColor: '#E89B09',
  },
  [userRoleEnum.CoOwner]: {
    value: userRoleEnum.CoOwner,
    label: 'CoOwner',
    backgroundColor: '#FFF2D9',
    borderColor: '#E89B09',
  },
  [userRoleEnum.Manager]: {
    value: userRoleEnum.Manager,
    label: 'Manager',
    backgroundColor: '#FFF2D9',
    borderColor: '#E89B09',
  },
  [userRoleEnum.Internal]: {
    value: userRoleEnum.Internal,
    label: 'Internal',
    backgroundColor: '#D9FFE7',
    borderColor: '#0E6D35',
  },
  [userRoleEnum.External]: {
    value: userRoleEnum.External,
    label: 'External',
    backgroundColor: '#CFECFF',
    borderColor: '#003ACC',
  },
  [userRoleEnum.General]: {
    value: userRoleEnum.General,
    label: 'General',
    backgroundColor: '#CFECFF',
    borderColor: '#003ACC',
  },
};

export type userProjectRoleType = keyof typeof userProjectRoleConst;

export enum clientType {
  android = 1,
  ios = 2,
  web = 3,
}

export interface IUser {
  userId: number;
  fullName: string;
  email: string;
  photoUrl: string;
  abbreviationBgColor: string;
  nameAbbreviation: string;
  isDelete: boolean;
  roleId?: userRoleEnum;
  projectRoleId?: userRoleEnum;
}

export enum UserAttendanceRecordRequestStatusEnum {
  Submitted = 1,
  Approved = 2,
  Declined = 3,
}

export const userAttendanceRecordRequestStatusConst: Record<
  UserAttendanceRecordRequestStatusEnum,
  {
    value: UserAttendanceRecordRequestStatusEnum;
    label: string;
    backgroundColor: string;
  }
> = {
  [UserAttendanceRecordRequestStatusEnum.Submitted]: {
    value: UserAttendanceRecordRequestStatusEnum.Submitted,
    label: 'Submitted',
    backgroundColor: '#000000',
  },
  [UserAttendanceRecordRequestStatusEnum.Approved]: {
    value: UserAttendanceRecordRequestStatusEnum.Approved,
    label: 'Approved',
    backgroundColor: '#B5FDD0',
  },
  [UserAttendanceRecordRequestStatusEnum.Declined]: {
    value: UserAttendanceRecordRequestStatusEnum.Declined,
    label: 'Declined',
    backgroundColor: '#F8E1B5',
  },
};
