import { createApi } from '@reduxjs/toolkit/query/react';
import { memberApi } from '@/lib/member/memberAPI';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { GroupDetail, RemoveUserRequest } from './interface';

export const groupApi = createApi({
  reducerPath: 'groupApi',
  tagTypes: ['inviteMemberToGroup'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    GetGroupDetail: builder.query<GroupDetail, number>({
      query: (groupId) => ({
        url: `/api/group/GetGroupDetail`,
        method: 'GET',
        params: { groupId },
      }),
      transformResponse: (response) => response.data.data,
      keepUnusedDataFor: 0,
      providesTags: ['inviteMemberToGroup'],
    }),
    UpdateGroupGeofenceRadius: builder.mutation<any, number>({
      query: (data) => ({
        url: `/api/group/UpdateGroupGeofenceRadius`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    removeUserFromGroup: builder.mutation<any, RemoveUserRequest>({
      query: (data) => ({
        url: `/api/group/RemoveUser`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(memberApi.util.invalidateTags(['members']));
      },
    }),
  }),
});

export const {
  useGetGroupDetailQuery,
  useUpdateGroupGeofenceRadiusMutation,
  useRemoveUserFromGroupMutation,
} = groupApi;
