import { RefreshToken } from '@/lib/users/interface';
import { clientType } from '@/types/constants/user';
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

export async function getNewAccessToken(data: RefreshToken): Promise<string> {
  try {
    // 目前 refreshToken 需要帶入舊的 accessToken
    const currentToken = localStorage.getItem('token');
    const response = await axios.post('/api/user/refreshToken', data, {
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    });
    return response.data.data.token;
  } catch (error) {
    console.error('Failed to refresh token:', error);
    throw error;
  }
}

const codeMessage: { [key: number]: string } = {
  400: '請確認帳號或密碼是否有誤。',
  401: '沒有權限或Token過期。',
  403: '禁止存取，請與管理者確認權限。',
  404: '請檢查連線資訊是否正確。',
  410: '資源已被刪除，無法存取。',
  500: '伺服器異常，請聯絡管理員。',
  502: '伺服器連線錯誤。',
  503: '系統暫時無法使用，維護作業中。',
  504: '伺服器連線逾時。',
};

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (
  error: AxiosError | null,
  token: string | null = null,
) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const errorHandler = async (error: AxiosError) => {
  if (error.response) {
    const { status } = error.response;
    const errorText = codeMessage[status] || error.response.statusText;

    if (status === 401) {
      const originalRequest = error.config as InternalAxiosRequestConfig & {
        _retry?: boolean;
      };
      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return instance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        const userId = localStorage.getItem('userId');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!userId || !refreshToken) {
          return Promise.reject(error);
        }

        try {
          const newAccessToken = await getNewAccessToken({
            userId: Number(userId),
            refreshToken,
            clientType: clientType.web,
          });
          // 將新的 token 儲存在 localStorage 中
          localStorage.setItem('token', newAccessToken);

          // 更新 Axios 實例的默認 headers
          instance.defaults.headers.common.Authorization =
            `Bearer ${newAccessToken}`;
          // 更新原始請求的 headers
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          processQueue(null, newAccessToken);
          return await instance(originalRequest);
        } catch (refreshError) {
          processQueue(refreshError, null);
          localStorage.removeItem('token');
          // 可以在這裡添加重定向到登錄頁面的邏輯
          return await Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      }
    } else {
      console.log(errorText);
    }
  }
  return Promise.reject(error);
};

// 創建 axios 實例
const instance = axios.create();

interface CustomAxiosRequestHeaders extends AxiosRequestHeaders {
  ClientType: string;
  Authorization: string;
  GroupId: string;
  Timezone: string;
}

// request 攔截器
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    const groupId = localStorage.getItem('groupId');

    config.headers = {
      ...config.headers,
      ClientType: '3', // web
      Authorization: token ? `Bearer ${token}` : '',
      GroupId: groupId || '',
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    } as CustomAxiosRequestHeaders;

    return config;
  },
  (error: AxiosError) => {
    errorHandler(error);
    return Promise.reject(error);
  },
);

// response 攔截器
instance.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, errorHandler);

export default instance;
